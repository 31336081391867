<template>
  <div
    class="skeleton"
    :style="{
      width: width,
      height: height,
      borderRadius: borderRadius,
    }"
  ></div>
</template>
<script>
export default {
  name: 'Skeleton',
  props: {
    width: {
      type: String,
      default: '0',
    },
    height: {
      type: String,
      default: '0.5',
    },
    borderRadius: {
      type: String,
      default: '0',
    },
  },
};
</script>
<style lang="less" scoped>
.skeleton {
  display: inline-block;
  // animation: identifier 2s ease-out infinite;
  background-size: 400% 400%;
  background-image: linear-gradient(
    -90deg,
    #efefef 0%,
    #efefef 50%,
    #efefeff4 100%
  );
}
// @keyframes identifier {
//   0% {
//     background-position: 0% 0%;
//   }
//   100% {
//     background-position: -135% 0%;
//   }
// }
</style>
