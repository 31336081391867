<!-- 我的 -->
<template>
  <UserLoading v-if="isInitLoading" />
  <div
    v-else
    style="min-height: 100vh"
    class="user"
    :style="{ overflow: showList ? 'hidden' : null }"
  >
    <div class="box" v-if="show" @click="show = false">
      <div>
        <img :src="posterUrl" alt="" />
      </div>
    </div>
    <!-- <van-popup v-model="show">
      <poster />
    </van-popup> -->
    <!-- 遮罩层组件 -->
    <div class="topHead">
      <div class="setting" @click="set">
        <img src="../../../static/image/设置.png" alt="" />
      </div>
      <div class="share" @click="share">
        <img src="../../../static/image/share.png" alt="" />
      </div>
      <div class="not_log">
        <div class="head">
          <img class="userImg" :src="avatarSrc" alt="" @click="img($event)" />
          <div class="isHave">
            <img v-if="anyUserinfo.isHave" :src="anyUserinfo.isHave" alt="" />
          </div>
        </div>
        <div class="text">
          <div class="log_text" v-if="ifLogined">
            {{ name }}
          </div>
          <div @click="ljdl" v-else class="log_text">未登录</div>
          <!-- 点击登录，显示模态框 -->
          <div class="logo_copy" v-if="ifLogined">
            <div class="log_copy" v-text="add"></div>
            <img
              src="../../../static/image/fuzhi@2x.png"
              @click="copy"
              alt=""
            />
          </div>
        </div>
        <div class="sign" @click="goTo('/sign')">
          <img src="../../assets/images/qiandao.png" alt="" />
          <div>签到</div>
        </div>
      </div>

      <div class="but">
        <!-- <van-button class="all_but" @click="allorder">全部订单</van-button>
        <van-button class="all_but" @click="goTo('/account')"
          >我的账户</van-button
        > -->
        <!-- <van-button class="all_but" @click="money">我的钱包</van-button> -->
        <div class="nav">
          <div class="navItem" @click="allorder">
            <div class="img">
              <img src="../../assets/images/dingdan.png" alt="" />
            </div>
            <div class="name">我的订单</div>
          </div>
          <div class="navItem" @click="goTo('/account')">
            <div class="img">
              <img src="../../assets/images/zhanghu.png" alt="" />
            </div>
            <div class="name">我的账户</div>
          </div>
          <div class="navItem" @click.stop="goTo('/merge')">
            <div class="img">
              <img src="../../assets/images/hecheng.png" alt="" />
            </div>
            <div class="name">合成</div>
          </div>
          <div class="navItem" @click.stop="goTo('/swap')">
            <div class="img">
              <img src="../../assets/images/duihuan.png" alt="" />
            </div>
            <div class="name">盲盒兑换</div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="mine">我的藏品</div>

      <div class="input_box">
        <div>
          <input
            type="text"
            v-model="commodityName"
            placeholder="输入藏品名称"
          />
        </div>
        <div class="input_text" @click="query(commodityName)">搜索</div>
      </div>

      <div class="screen" v-if="anyUserinfo.lastToken">
        <div
          class="left"
          @click="changescreen(0)"
          :class="screen === 0 ? 'active' : null"
        >
          <span>持有数量</span>
          <img
            :src="
              screen !== 0
                ? dao
                : queryScreen.numSort == 'desc'
                ? zhengActive
                : daoActive
            "
            alt=""
          />
        </div>
        <div
          class="middle"
          :class="screen === 1 ? 'active' : null"
          @click="changescreen(1)"
        >
          <span>获得时间</span>
          <img
            :src="
              screen !== 1
                ? dao
                : queryScreen.timeSort == 'desc'
                ? zhengActive
                : daoActive
            "
            alt=""
          />
        </div>
        <div
          class="right"
          :class="screen === 2 ? 'active' : null"
          @click="changescreen(2)"
        >
          <span>筛选</span>
          <i></i>
        </div>
      </div>

      <div class="shop_box">
        <div v-if="!anyHavegoodslist.length" class="def">
          <img src="../../../static/image/hezi2.png" alt="" />
          <p>暂无数据</p>
        </div>
        <div
          class="si"
          @click="fold(item)"
          v-for="item in anyHavegoodslist"
          :key="item.id"
        >
          <div class="content">
            <img :src="item.listPic" alt="" />
            <div class="name" v-if="item.belong != 2">
              <div class="title">{{ item.name }}</div>
              <!-- <div class="fold" v-if="item.count > 1">展开</div> -->
            </div>
            <div class="open" v-if="item.belong == 2" v-show="(open = true)">
              <span @click.stop="influence(item.id)">点击开启盲盒</span>
            </div>
          </div>
          <div class="num">
            持有数量: {{ item.belong == 5 ? item.position : item.count || 0 }}
          </div>
        </div>
      </div>
    </div>

    <!-- 开启盲盒-----遮罩层 -->
    <van-overlay :show="mystery">
      <div class="mystery_nei">
        <div class="mystery_block">
          <div class="mystery_text">开启盲盒</div>
          <div class="mystery_but">
            <div class="mystery_one" @click="mysteryOne">取消</div>
            <div class="mystery_tow" @click="mysteryBut">确认</div>
          </div>
        </div>
      </div>
    </van-overlay>

    <!-- 实名认证  -->
    <van-overlay :show="autonym">
      <div class="wrapper">
        <div class="block">
          <div>你还未实名认证</div>
          <div>请先实名认证</div>
          <div class="over_box">
            <div class="over_none" @click="autonym = false">取消</div>
            <div class="over_en" @click="$router.push('/Autonym')">确认</div>
          </div>
        </div>
      </div>
    </van-overlay>

    <div class="preview" v-if="isShowPreview">
      <div class="content">
        <!-- <img :src="listPic" alt="" /> -->
        <van-swipe
          class="my-swipe"
          indicator-color="white"
          width="200"
          style="width: 200px"
        >
          <van-swipe-item v-for="(item, index) in imageList" :key="index">
            <img :src="item.listPic" alt="" />
          </van-swipe-item>
        </van-swipe>
        <div class="close" @click="isShowPreview = false"></div>
      </div>
    </div>

    <div class="commodityList" v-if="showList">
      <div class="wrapper">
        <div class="box" @click.stop="showList = false"></div>
        <div class="block">
          <div class="product">
            <div>
              <div class="checkedbox">
                <div
                  style="font-size: 16px; font-weight: 600; margin-right: 10px"
                >
                  数量: {{ postionGoodNumberListCount }}
                </div>
                <div class="checkbox">
                  <div class="checkBoxChild" @click="sortList('goodsNumber')">
                    编号
                    <div>
                      <i
                        class="just"
                        :class="
                          userPostionGoodNumberQuery.keyName ===
                            'goodsNumber' && goodsNumber.sort === 'desc'
                            ? 'active'
                            : null
                        "
                      ></i
                      ><i
                        class="inverted"
                        :class="
                          userPostionGoodNumberQuery.keyName ===
                            'goodsNumber' && goodsNumber.sort === 'asc'
                            ? 'active'
                            : null
                        "
                      ></i>
                    </div>
                  </div>
                  <div class="checkBoxChild" @click="sortList('status')">
                    状态
                    <div>
                      <i
                        class="just"
                        :class="
                          userPostionGoodNumberQuery.keyName === 'status' &&
                          status.sort === 'desc'
                            ? 'active'
                            : null
                        "
                      ></i
                      ><i
                        class="inverted"
                        :class="
                          userPostionGoodNumberQuery.keyName === 'status' &&
                          status.sort === 'asc'
                            ? 'active'
                            : null
                        "
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              @click="showList = false"
              style="color: #2376ff; font-size: 14px"
            >
              收起
            </div>
          </div>

          <!-- <div style="padding-bottom: 60px; overflow: auto" ref="scroll"> -->
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            loading-text="数据加载中..."
            style="padding-bottom: 70px; overflow: auto"
            @load="onLoad"
            :immediate-check="false"
          >
            <div
              class="product_list"
              v-for="(list, index) in postionGoodNumberList"
              :key="index"
              @click="collection(list)"
            >
              <div class="product_num">
                <div class="product_name">{{ list.goodsNumber }}</div>

                <div
                  class="num_several"
                  :class="
                    list.status == 0
                      ? 'unActive'
                      : list.status == 3
                      ? 'active'
                      : null
                  "
                >
                  <span>
                    {{
                      list.status == 0
                        ? "未寄卖"
                        : list.status == 3
                        ? "寄卖中"
                        : null
                    }}
                  </span>
                  <span style="margin-left: 5px" v-if="list.is_Lock == 1">
                    已锁仓
                  </span>
                </div>
              </div>
              <div class="product_text">
                ￥{{ list.status == 0 ? list.currentPrice : list.sale_price }}
                <img
                  class="product_img"
                  src="../../../static/image/right.png"
                  alt=""
                />
              </div>
            </div>
            <!-- <div
              class="even"
              @click="more"
              v-if="postionGoodNumberListCount > postionGoodNumberList.length"
            >
              查看更多
              <img src="../../../static/image/bottom.png" alt="" />
            </div> -->
          </van-list>
        </div>
      </div>
    </div>

    <!-- 合并 -->
    <div
      class="merge"
      ref="merge"
      v-if="false"
      v-show="token && $route.name === 'User'"
      @click.stop="$router.push('/merge')"
    >
      <img src="../../../static/image/merge.png" alt="" />
    </div>

    <div class="selectPayType" v-if="isShowSign">
      <div class="selectPayTypeBox"></div>
      <div class="bg">
        <div class="content">请在APP内完成签到</div>
        <div class="btnGroup">
          <div class="unCompleted" @click="isShowSign = false">取消</div>
          <div class="completed" @click="openApp">去下载app</div>
        </div>
      </div>
    </div>

    <van-overlay :show="showType" @click="showType = false">
      <div class="sex" @click.stop>
        <van-picker
          title="选择分类"
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="showType = false"
        >
          <!-- @cancel="onCancel" -->
        </van-picker>
      </div>
    </van-overlay>
  </div>
</template>

<script>
// 引入登录接口
import headImg from "@/assets/images/touxiang.png"; //默认头像
import Poster from "../../components/vue_qr/index";
import SilkBall from "silk-ball";
import UserLoading from "./UserLoading.vue";
import screenPng from "../../assets/images/screen.png";
import screenActive from "../../assets/images/screen_active.png";
import dao from "../../assets/images/dao.svg";
import daoActive from "../../assets/images/dao_active.svg";
import zheng from "../../assets/images/zheng.svg";
import zhengActive from "../../assets/images/zheng_active.svg";
export default {
  name: "user",
  components: { Poster, UserLoading },
  data() {
    return {
      screenPng,
      screenActive,
      dao,
      daoActive,
      zheng,
      zhengActive,
      screen: 0,
      isShowSign: false,
      isShowPreview: false,
      finished: false,
      loading: false,
      listPic: "",
      open: false, //点击开启盲盒
      mystery: false,
      autonym: false,
      show: false,
      showType: false,
      pwd: "",
      avatarSrc: headImg, //头像
      ifLogined: false, // 登录状态\
      anyUserinfo: {},
      add: "",
      name: "",
      // 藏品
      anyHavegoodslist: [],
      blind: "",
      commodityName: "",
      token: false,
      iduser: "", //是否实名认证
      idcardStatus: false,
      showList: false,
      isInitLoading: true,
      postionGoodNumberListCount: 0,
      postionGoodNumberList: [],
      imageList: [],
      posterUrl: "",
      queryScreen: {
        numSort: "",
        timeSort: "",
        type: null,
      },
      userPostionGoodNumberQuery: {
        keyName: undefined,
        sort: undefined,
        pageindex: 1,
        pagesize: 20,
        goodsId: undefined,
      },
      goodsNumber: {
        sort: "",
      },
      status: {
        sort: "",
      },
      isAndroid:
        navigator.userAgent.indexOf("Android") > -1 ||
        navigator.userAgent.indexOf("Adr") > -1,
      isiOS: !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
      columns: [],
    };
  },
  mounted() {
    this.isInitLoading = true;
    this.getStatus();
    this.getCommodityList();
    this.token = localStorage.getItem("token");
    setTimeout(() => {
      new SilkBall(this.$refs.merge);
    }, 1000);
  },
  methods: {
    onConfirm(value) {
      this.queryScreen.type = value.id;
      this.getCommodityList();
      this.showType = false;
    },
    changescreen(key) {
      this.screen = key;
      // this.queryScreen.type = null;
      if (key === 0) {
        this.queryScreen.numSort =
          this.queryScreen.numSort == "desc" ? "asc" : "desc";
        this.queryScreen.timeSort = null;
        this.getCommodityList();
      } else if (key === 1) {
        this.queryScreen.timeSort =
          this.queryScreen.timeSort == "desc" ? "asc" : "desc";
        this.queryScreen.numSort = null;
        this.getCommodityList();
      } else {
        //
        this.showType = true;
        if (this.columns.length) return;
        this.$api.anyTypelist().then((res) => {
          if (res.code == 0) {
            this.columns = [
              {
                id: 0,
                text: "全部",
              },
              ...res.data.map((e) => {
                e.text = e.name;
                return e;
              }),
            ];
          }
        });
      }
    },
    openApp() {
      window.open("https://v.hxnft.shop/appDownload/index.html");
      this.isShowSign = false;
      // if (this.isAndroid) {
      //   window.open('scheme://huaxiashuyi:8888/openApp', '_self');
      // } else if (this.isiOS) {
      //   window.open('TounarInformationHXSYIBoxAPP://', '_self');
      // }
    },
    onLoad() {
      if (this.postionGoodNumberListCount > this.postionGoodNumberList.length) {
        this.userPostionGoodNumberQuery.pageindex++;
        this.getUserPostionGoodNumberList("more");
      } else {
        this.finished = true;
      }
    },
    // 我的账户
    goTo(path) {
      let token = localStorage.getItem("token");
      if (token == null) {
        this.$router.push("/login");
      } else if (path === "/sign") {
        this.isShowSign = true;
        // this.$router.push(`${path}?token=${localStorage.getItem('token')}`);
      } else {
        this.$router.push(path);
      }
    },
    getUserPostionGoodNumberList(isMore) {
      const keyName = this.userPostionGoodNumberQuery.keyName;
      const query = {
        goodsId: this.userPostionGoodNumberQuery.goodsId,
        key: keyName,
        sort: keyName ? this[keyName].sort : undefined,
        token: localStorage.getItem("token"),
        pageindex: this.userPostionGoodNumberQuery.pageindex,
        pagesize: this.userPostionGoodNumberQuery.pagesize,
      };
      this.$api.userPostionGoodNumberList(query).then(
        (e) => {
          this.loading = false;
          if (e.code == 0) {
            this.postionGoodNumberListCount = e.count;
            if (isMore) {
              this.postionGoodNumberList = this.postionGoodNumberList.concat(
                e.data
              );
            } else {
              this.postionGoodNumberList = e.data;
            }
          }
        },
        () => {
          this.loading = false;
        }
      );
    },
    // 点击更多
    more() {
      this.userPostionGoodNumberQuery.pageindex++;
      this.getUserPostionGoodNumberList("more");
    },
    sortList(key) {
      this.userPostionGoodNumberQuery.pageindex = 1;
      this.finished = false;
      this.userPostionGoodNumberQuery.keyName = key;
      this[key].sort = this[key].sort === "asc" ? "desc" : "asc";
      this.getUserPostionGoodNumberList();
    },
    query(name) {
      if (!name) return this.$toast("请输入搜索内容");
      this.getCommodityList(name);
    },
    getCommodityList(name) {
      // 我的藏品列表
      let params = {
        pageindex: 1,
        pagesize: 1000,
        token: localStorage.getItem("token"),
        name,
      };
      if (this.queryScreen.type) {
        params.type = this.queryScreen.type;
      }
      if (this.queryScreen.numSort) {
        params.numSort = this.queryScreen.numSort;
      }
      if (this.queryScreen.timeSort) {
        params.timeSort = this.queryScreen.timeSort;
      }
      this.$api.userBuyShopList(params).then(
        (res) => {
          this.isInitLoading = false;
          if (res.code == 0) {
            this.anyHavegoodslist = res.data;
          }
        },
        () => {
          this.isInitLoading = false;
        }
      );
    },
    // 点击复制
    copy() {
      let url = this.add;
      //新建一个文本框
      let oInput = document.createElement("input");
      //赋值给文本框
      oInput.value = url;
      document.body.appendChild(oInput);
      // 选择对象;
      oInput.select();
      // 执行浏览器复制命令
      document.execCommand("Copy");
      //复制完成删除掉输入框
      oInput.remove();
      this.$toast("复制成功");
    },

    // 点击开启盲盒
    influence(id) {
      this.blind = id;
      this.mystery = true;
    },
    mysteryBut() {
      let params = {
        token: localStorage.getItem("token"),
        blindBoxId: this.blind,
      };
      this.$api.openBlindBox(params).then((res) => {
        this.mystery = false;
        if (res.code == 0) {
          this.getCommodityList();
          // this.listPic = res.data.listPic;
          this.imageList = res.data;
          this.isShowPreview = true;
        }
        this.$toast(res.msg);
      });
    },
    mysteryOne() {
      this.mystery = false;
    },
    collection(item) {
      if (item.belong != 2) {
        this.$router.push({ path: "/collection", query: { id: item.id } });
      }
    },
    getStatus() {
      let params = {
        token: localStorage.getItem("token"),
      };
      this.$api.anyUserinfo(params).then((res) => {
        if (res.code == 0) {
          this.idcardStatus = res.data.idcardStatus === 1;
          this.anyUserinfo = res.data;
          this.ifLogined = true;
          let name = this.anyUserinfo.nickName.slice(0, 5);
          this.name = name;
          this.avatarSrc = this.anyUserinfo.headImg || headImg;
          let add = this.anyUserinfo.address;
          this.add = add;
          this.iduser = res.data.idcardStatus; //实名认证
        }
      });
    },

    // 点击头像放大
    img() {},
    allorder() {
      if (!localStorage.getItem("token")) {
        return this.$router.push("/login");
      }
      // Toast("即将呈现，敬请期待");
      this.$router.push("/Allorder");
    },
    money() {
      // Toast('即将呈现，敬请期待');
      let token = localStorage.getItem("token");
      if (token == null) {
        this.$router.push("/login");
      } else {
        this.$router.push("/money");
      }
    },

    // 展开
    fold(item) {
      this.userPostionGoodNumberQuery.pageindex = 1;
      this.finished = false;
      if (item.count > 1) {
        this.showList = true;
        this.userPostionGoodNumberQuery.goodsId = item.goodsId;
        this.getUserPostionGoodNumberList();
      } else {
        this.collection(item);
      }
    },

    share() {
      let token = localStorage.getItem("token");
      if (token == null) {
        this.show = false;
        this.$router.push("/login");
      } else {
        this.$api.poster({ token: localStorage.getItem("token") }).then((e) => {
          this.posterUrl = e.data.url;
          this.show = true;
        });
      }
    },
    set() {
      let token = localStorage.getItem("token");
      if (token == null) {
        this.$router.push("/login");
      } else {
        this.$router.push("/Set");
      }
    },
    // 点击立即登录，显示登录模态框
    ljdl() {
      // this.ifShowModal = true;
      this.$router.push("/login");
    },
  },
};
</script>
<style lang="less" scoped>
.box {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    text-align: center;
    img {
      width: 75%;
    }
  }
}
/deep/.van-overlay {
  // background-color: #eeeded42;
  z-index: 10;
}
.mystery_nei {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .mystery_block {
    background-color: #fff;
    width: 250px;
    height: 150px;
    border-radius: 10px;
    .mystery_text {
      font-size: 20px;
      text-align: center;
      line-height: 50px;
      margin-top: 20px;
    }
    .mystery_but {
      display: flex;
      font-size: 16px;
      justify-content: space-around;
      margin-top: 20px;
      .mystery_tow {
        height: 30px;
        line-height: 30px;
        width: 80px;
        text-align: center;
        background-color: black;
        color: #ffffff;
      }
      .mystery_one {
        height: 30px;
        line-height: 30px;
        width: 80px;
        text-align: center;
        // background-color: ;
        border: 1px black solid;
      }
    }
  }
}
.dian {
  z-index: 10;
  position: relative;
  color: #ffffff;
  top: -70px;
  // right: -120px;
}
.open {
  // z-index: 55;
  position: absolute;
  top: 0;
  font-size: 14px;
  right: 0;
  text-align: center;
  color: #ffffff;
  background-color: #00000060;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    background-color: #0303039d;
    display: inline-block;
    border-radius: 5px;
  }
}
.shop {
  font-size: 20px;
  color: #6c6c6c;
  text-align: center;
  line-height: 200px;
}
.text {
  width: 100%;
}
.but {
  width: 100%;
  margin-top: 10px;
  .nav {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: #fff;
    margin: 20px 17px;
    border-radius: 5px;
    padding: 20px 0 15px;
    .navItem {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .img {
        width: 26px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .name {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #999999;
        margin-top: 12px;
      }
      img {
        max-height: 26px;
        max-width: 26px;
      }
    }
  }
}
.merge {
  position: fixed;
  right: 0;
  bottom: 100px;
  z-index: 10000;
  img {
    width: 60px;
  }
}
.all_but {
  width: 116px;
  height: 29px;
  color: #ffffff;
  margin-left: 57px;
  background: linear-gradient(to bottom, #050203, #363736);
  border: 1px #ffffff solid;
}
.shop_box {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 60px;
  .def {
    text-align: center;
    margin: 0 auto;
    width: 100%;
    img {
      width: 35%;
      margin-right: -20px;
    }
    p {
      font-size: 14px;
      color: #aaa;
    }
  }
}
.si {
  width: 150px;
  height: 220px;
  margin-left: 25px;
  margin-top: 18px;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 10px 39px 7px rgba(1, 15, 45, 0.05);
  .num {
    font-size: 12px;
    color: #333333;
    padding: 8px 5px;
  }
  .content {
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    flex: 1;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .name {
      z-index: 9;
      position: absolute;
      color: #ffffff;
      bottom: 0;
      left: 0;
      right: 0;
      font-size: 0.37333rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      height: 30px;
      padding: 5px;
      padding-top: 10px;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      display: flex;
      align-items: flex-end;
      .title {
        font-size: 12px;
        flex: 2;
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .fold {
        font-size: 12px;
        flex: 1;
        color: #fff;
        text-align: right;
      }
    }
  }
}
.input_box {
  display: flex;
  font-size: 14px;
  padding: 0 20px;
  height: 1rem;
  display: flex;
  align-items: center;
  input {
    background-color: #f3f4f8;
    border: 0;
    width: 260px;
    padding-left: 17px;
    font-size: 12px;
    height: 1rem;
    border-bottom-left-radius: 16px;
    border-top-left-radius: 16px;
  }
  .input_text {
    background-color: #f3f4f8;
    line-height: 40px;
    padding-right: 17px;
    height: 1rem;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }
}
.screen {
  display: flex;
  align-items: center;
  margin-top: 15px;
  div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 30px;
    }
    span {
      font-size: 14px;
      color: #666;
    }
    i {
      display: block;
      width: 8px;
      height: 8px;
      border-bottom: 1px solid #999;
      border-right: 1px solid #999;
      transform: rotate(45deg);
      margin-left: 10px;
    }
    &.active {
      span {
        color: #333333;
      }
      i {
        border-bottom: 1px solid #666;
        border-right: 1px solid #666;
      }
    }
  }
}
.mine {
  font-size: 16px;
  line-height: 36px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 600;
}
.log_text {
  font-size: 14px;
  color: #ffffff;
}
.logo_copy {
  display: flex;
  align-items: center;
  img {
    width: 15px;
    height: 15px;
  }
}
.log_copy {
  font-size: 12px;
  color: #b1b1b1;
  width: 120px;
  overflow: hidden; /*超出的部分隐藏起来。*/
  white-space: nowrap;
  text-overflow: ellipsis;
}
.not_log {
  padding: 70px 16px 0;
  display: flex;
  align-items: center;
}
.sign {
  font-size: 12px;
  color: #ffd52d;
  display: flex;
  align-items: center;
  white-space: nowrap;
  background: rgba(238, 225, 176, 0.1);
  border-radius: 16px;
  padding: 7px 15px;
  img {
    width: 20px;
    margin-right: 5px;
  }
}
.head {
  margin-right: 15px;
  position: relative;
  img.userImg {
    border-radius: 50%;
    width: 60px;
    height: 60px;
  }
  .isHave {
    position: absolute;
    top: -23px;
    width: 82px;
    left: -10px;
    img {
      width: 100%;
    }
  }
}
.user {
  background-image: url(../../assets/images/userBg.png);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: contain;
  background-color: #f8f9fb;
}
.topHead {
  width: 100%;
  color: #eeeded;
  // background: linear-gradient(to bottom, #050203, #363736);
  .setting {
    font-size: 12px;
    position: absolute;
    right: 16px;
    line-height: 52px;
    img {
      width: 20px;
      height: 17px;
      position: relative;
    }
  }
  .share {
    font-size: 12px;
    position: absolute;
    right: 50px;
    line-height: 52px;
    img {
      width: 20px;
      height: 17px;
      position: relative;
    }
  }
}
.block {
  font-size: 16px;
  text-align: center;
  border-radius: 5px;
  padding-top: 30px;
  width: 300px;
  margin: 0 auto;
  margin-top: 60%;
  background-color: #fff;
}

.over_box {
  margin-top: 20px;
  padding-bottom: 30px;
  display: flex;
  justify-content: space-around;
  font-size: 13px;

  .over_none {
    color: #0754d3;
    width: 74px;
    text-align: center;
    line-height: 25px;
    height: 25px;
    border-radius: 5px;
    border: 1px #cecece solid;
  }

  .over_en {
    color: #ffffff;
    width: 74px;
    text-align: center;
    background-color: #0754d3;
    line-height: 25px;
    height: 25px;
    border-radius: 5px;
    border: 1px #0754d3 solid;
  }
}
.preview {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.4);
  .content {
    text-align: center;
    img {
      width: 200px;
    }
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .close {
      display: inline-block;
      margin-top: 50px;
      margin: 0 auto;

      //改叉叉大小 6：1等量放大缩小就行（算好的，省的自己调整太麻烦）
      width: 20px;
      height: 2px;

      background: #fff;
      line-height: 0;
      font-size: 0;
      vertical-align: middle;
      -webkit-transform: rotate(45deg);
      border-radius: 50%;
    }

    .close:after {
      content: "/";
      display: block;

      //改叉叉大小 6：1等量放大缩小就行（这里也要改）
      width: 20px;
      height: 2px;
      visibility: initial;
      background: #fff;
      -webkit-transform: rotate(-90deg);
    }
  }
}
.commodityList {
  z-index: 99;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  // background: rgba(0, 0, 0, 0.6);
  .wrapper {
    display: flex;
    // align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
    z-index: 9999;
  }
  .block {
    width: 100%;
    position: fixed;
    bottom: 0;
    height: 70%;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 0;
    position: relative;
    z-index: 9999;
  }
  .product {
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    align-items: center;
    img {
      width: 12px;
      height: 12px;
    }
  }
  .checkedbox {
    align-items: center;
    display: flex;
    font-size: 12px;
    align-items: center;
    justify-content: space-between;
  }
  .checkbox {
    display: flex;
    padding-right: 10px;
    div.checkBoxChild {
      margin: 0 10px;
      display: flex;
      align-items: center;
      i {
        display: flex;
        flex-direction: column;
        margin: 3px 0;
        width: 10px;
        height: 10px;
        margin-left: 5px;
        &.just {
          &::after {
            display: block;
            content: "";
            border-top: 5px solid transparent;
            border-bottom: 5px solid #ddd;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
          }
          &.active {
            &.just {
              &::after {
                border-bottom: 5px solid #333;
              }
            }
          }
        }
        &.inverted {
          &::after {
            display: block;
            content: "";
            border-top: 5px solid #ddd;
            border-bottom: 5px solid transparent;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
          }
          &.active {
            &.inverted {
              &::after {
                border-top: 5px solid #333;
              }
            }
          }
        }
      }
    }
  }
  .product_list {
    display: flex;
    font-size: 16px;
    line-height: 60px;
    justify-content: space-between;
    margin-right: 20px;
    .product_text {
      display: flex;
      align-items: center;
    }
    .product_num {
      margin-left: 10px;
      display: flex;
      font-size: 12px;
      flex: 1;
      min-width: 1px;
    }
    .product_name {
      white-space: nowrap;
      min-width: 1px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .num_several {
      margin-left: 5px;
      font-size: 10px;
      white-space: nowrap;
      color: #aaa;
      &.active {
        color: #fab878;
        span {
          padding: 3px;
        }
      }
      &.unActive {
        color: #cecece;
        span {
          background: #f8f8f8;
          padding: 3px;
        }
      }
      &.lock {
        color: #009944;
        span {
          background: #deffee;
          padding: 3px;
        }
      }
    }
    .product_img {
      width: 4px;
      height: 6px;
      margin-left: 5px;
    }
  }
  .even {
    font-size: 12px;
    color: #abaab9;
    background-color: #abaab957;
    width: 70px;
    text-align: center;
    border-radius: 10px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding-left: 15px;
    img {
      width: 6px;
      height: 4px;
    }
  }
}
.selectPayType {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 99999;
  .selectPayTypeBox {
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
  .bg {
    width: 80%;
    height: 160px;
    background: #ffffff;
    border-radius: 5px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .content {
      font-size: 16px;
      color: #666666;
      margin: 35px 0 35px;
      text-align: center;
    }
    .btn {
      width: 60%;
      height: 35px;
      margin: 0 auto;
      background: #0754d3;
      border-radius: 3px;
    }
    .btnGroup {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px;
      div {
        background: #0754d3;
        border-radius: 3px;
        font-size: 14px;
        color: #fff;
        width: 90px;
        height: 33px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid transparent;
        &.unCompleted {
          border: 1px solid #0754d3;
          background: transparent;
          color: #0754d3;
        }
      }
    }
  }
}
.my-swipe {
  .van-swipe-item {
    color: #fff;
    text-align: center;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
.sex {
  width: 100%;
  position: absolute;
  bottom: 0;
}
</style>
