<template>
  <div>
    <div class="top">
      <div class="header">
        <Skeleton height="25px" width="25px" />
        <Skeleton style="margin-left: 15px" height="25px" width="25px" />
      </div>
      <div class="user">
        <div class="img">
          <Skeleton height="60px" width="60px" borderRadius="30px" />
        </div>
        <div class="title">
          <Skeleton style="margin-bottom: 10px" height="18px" width="100px" />
          <Skeleton height="10px" width="150px" />
        </div>
      </div>
      <div class="btn">
        <Skeleton height="25px" width="100px" />
        <Skeleton height="25px" width="100px" />
      </div>
    </div>
    <div style="padding: 20px">
      <div style="text-align: center">
        <Skeleton height="25px" width="80px" />
      </div>
      <div>
        <Skeleton height="25px" width="100%" />
      </div>
    </div>
    <div class="list">
      <div class="item">
        <Skeleton height="200px" width="100%" />
      </div>
      <div class="item">
        <Skeleton height="200px" width="100%" />
      </div>
      <div class="item">
        <Skeleton height="200px" width="100%" />
      </div>
      <div class="item">
        <Skeleton height="200px" width="100%" />
      </div>
    </div>
  </div>
</template>

<script>
import Skeleton from '../../components/skeleton';
export default {
  components: { Skeleton },
  name: 'HomeLoading',
};
</script>

<style lang="less" scoped>
.top {
  background: #f8f8f8;
  padding: 20px;
}
.header {
  display: flex;
  justify-content: flex-end;
}
.user {
  display: flex;
  .title {
    display: flex;
    margin-left: 20px;
    justify-content: center;
    flex-direction: column;
  }
}
.btn {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}
.list {
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px;
  .item {
    width: 50%;
    padding: 5px 10px;
    box-sizing: border-box;
  }
}
</style>
